<template>
  <v-app>
    <v-navigation-drawer app clipped v-if="isSignedin">
      <v-list-item :to="{ name: 'dashboard' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Home </v-list-item-title>
          <v-list-item-subtitle> Página Inicial </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'api' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6">Legado</v-list-item-title>
          <v-list-item-subtitle>API do Legado</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'apiData' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6"> API Dados</v-list-item-title>
          <v-list-item-subtitle>API de Integração</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'apiDataImport' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6"> API Importação</v-list-item-title>
          <v-list-item-subtitle>API de Integração</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'engagement' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6">Engajamento</v-list-item-title>
          <v-list-item-subtitle>API de Engajamento</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'performance' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6">Performance</v-list-item-title>
          <v-list-item-subtitle>API de Performance</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'growth' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Desenvolvimento
          </v-list-item-title>
          <v-list-item-subtitle>API de Desenvolvimento</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'notification' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6">Notificações</v-list-item-title>
          <v-list-item-subtitle>API de Notificações</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'management' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6">Configurações</v-list-item-title>
          <v-list-item-subtitle>API de Configurações</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'customSurveys' }">
        <v-list-item-content>
          <v-list-item-title class="text-h6"
            >Pesquisa Avançada</v-list-item-title
          >
          <v-list-item-subtitle>API de Pesquisa Avançada</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark fixed clipped-left v-if="isSignedin">
      <div class="d-flex align-center">
        <h2>TeamCulture Developer Portal</h2>
      </div>

      <v-spacer></v-spacer>

      <v-btn text @click="logout">
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="page">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { projectAuth } from '@/firebase/config'

export default {
  name: 'App',
  components: {},

  data: () => ({
    isSignedin: true,
  }),

  created() {
    this.isSignedin = false
    const $store = this.$store
    // console.log('[App.user]', user)
    projectAuth.onAuthStateChanged(async (user) => {
      if (user) {
        this.isSignedin = true
        // User is signed in.
        $store.dispatch('currentUser/loadCurrentUser', user)
      } else {
        this.isSignedin = false
        // User is signed out.
        $store.dispatch('currentUser/loadCurrentUser', {})
      }
    })
  },

  methods: {
    async logout() {
      projectAuth.signOut().then(() => {
        this.$router.push('/logout')
      })
    },
  },
  computed: {},
  mounted() {},
}
</script>

<style lang="scss">
@import '@/global.scss';

.page {
  background-color: $bg-1 !important;
  padding: 10px 35px !important;

  &.modal {
    height: calc(100vh - 24px);
    overflow: hidden;
  }
}
</style>
